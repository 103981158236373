import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Accordion from "components/Accordion/Accordion.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import image from "assets/img/web_banner_covid.jpg"
import { Helmet } from "react-helmet";

const faq=[
    {
        title:'What is Omicron?',
        description:<h5>
          <li>Omicron is the latest variant of the Covid-19 virus.</li>
          <li>The 1st wave of Covid-19 in india was caused by the beta variant.. The 2nd wave of Covid-19 in india was caused by the Delta Variant.</li>
          <li>The present 3rd wave is caused by Omicron.</li>
        </h5>,
        content:false
    },
    {
        title:'What is the difference between Delta and Omicron?',
        description:<h5>
          <li>Omicron causes a less severe form of Covid illness. Commonly presents as fever,body pain and cold like symptoms in majority of the people like any other viral fever.</li>
          <li>However, one person with Omicron can spread the infection to more number of individuals compared to the Delta variant.</li>
          <li>Omicron affects more of the upper respiratory System where as delta affects more of the lower respiratory system.</li>
        </h5>,
        content:false
    },
    {
        title:'What are the precautions to safeguard myself against Omicron?',
        description:<h5>Precautions against Omicron are the same as the previous variants. Continue following standard precautions such as social distancing, use of masks, hand hygiene [SMS-SOCIAL DISTANCING,Mask and sanitizer].</h5>,
        content:false
    },
    {
        title:'What is the treatment against Omicron?',
        description:<h5>
          <li>Treatment is symptomatic with antihistaminics and paracetamol since it is a viral infection. No need for any antibiotics.</li>
                      <li>Antibiotic cocktail (Casirivimab+ Imdevimab) is not effective against the Omicron variant as per the AIIMS/ICMR guidelines and as per the various studies conducted across the world.</li>
        </h5>,
        content:false
    },
    {
      title:'What is the efficacy of the vaccine against the Omicron variant?',
      description:<h5>2 Doses of Vaccination reduces the risk of developing severe illness. Studies have shown that those who develop complications from covid are those who are not vaccinated.</h5>,
      content:false
  },
  {
    title:'What is the booster dose and what is recommended in India?',
    description:<h5>
      <li>A booster dose is an additional dose of the Covid vaccine recommended to further increase your immunity against the virus.</li> 
					<li>In India, the 3rd dose offered is the same vaccine as your 1st and 2nd dose vaccine. It should be taken 9 months after the 2nd dose.</li>
    </h5>,
    content:false
  },
  {
    title:'What should I do if I have symptoms suggestive of Omicron?',
    description:<h5>
      <li>If you develop symptoms suggestive of Covid illness, do not panic. Isolate yourself at your home.</li> 
<li>Opt for consultation through telemedicine. Contact our specialists through the “Talk to Star” app or through our helpline 7676905905.</li>
<li>The doctors will guide you regarding general precautions, self-monitoring and provide consultations free of cost.</li>
    </h5>,
    content:false
  },
  {
    title:'Is Omicron (COVID-19 Variant) treatment covered in my health insurance policy?',
    description:<h5>If hospitalization is warranted as per the AIIMS/ICMR/State government guidelines it is payable by the insurance company. [<a href="https://www.icmr.gov.in/pdf/covid/techdoc/COVID_Clinical_Management_14012022.pdf" target="_blank" rel="noopener noreferrer nofollow">AIIMS/ICMR Clinical Management Protocol for COVID-19 Dated Jan 14 2022</a>].</h5>,
    content:false
  },
  {
    title:'Do I have to pay any extra premium for the coverage of COVID-19 and its variant Omicron?',
    description:<h5>NO there is no additional premium collected.</h5>,
    content:false
},{
  title:'What is the waiting period for omicron variant claim under health insurance?',
  description:<h5>
    <p>In all policies there is a waiting period of 30 days.</p> 
					<p>However in kavach and rakshak waiting period is 15 days.</p> 
  </h5>,
  content:false
},

]
        

const covid19faq1 = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });

    return (
        <Layout small={true}>
        <GridContainer className={classes.containerDisplay}>
        <img style={{width: "100%" , maxWidth : "100%", display : "block"}} src={image} />
        {/* <h2 className={classes.faqheader} >COVID19-FAQ</h2> */}
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
            <div>
            <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer
                className={`${classes.container} ${classes.textCenter}`}
                >
                <GridItem
                style={{ paddingLeft:'0px', marginTop: "15px"}}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <Accordion active={0} collapses={AccordionArray} />
                </GridItem>
                </GridContainer>
            </div>
            </div>
        </div>

      </GridItem>
    </GridContainer>
    <Helmet key="helmetTags">
        <title> Novel Coronavirus (COVID-19) FAQs</title>
      </Helmet>
    </Layout>
    
  );
};
covid19faq1.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default withStyles(WellnessBenefitsStyle)(covid19faq1);